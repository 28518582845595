<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card rounded-lg">
          <div class="card-body">
            <template v-if="!loadingBusinesses">
              <div class="row mb-md-2">
                <div class="col-sm-12 col-md-6">

                  <div class="d-flex align-items-center">
                    <div class="font-weight-bold mr-4">All Prospects & Leads</div>
                    <div class="form-inline">
                      <label>
                        Show&nbsp;
                      </label>
                      <b-form-select
                          v-model="datatable.queries.per_page"
                          class="ml-1 mr-1"
                          size="sm"
                          :options="[10, 15, 25, 50, 100]"
                          @change="getBusinesses"
                      ></b-form-select>
                    </div>
                    <div class="form-inline ml-3">
                      <label>
                        Status Filter
                      </label>
                      <b-form-select
                          v-model="datatable.queries.status"
                          class="ml-1 mr-1"
                          size="sm"
                          :options="['Lead','Mailed','Scanned', 'All']"
                          @change="getBusinesses"
                      ></b-form-select>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-6">
                  <div class="dataTables_filter text-md-right">
                    <vue-json-to-csv :json-data="leads" :csv-title="csvTitle()" :labels="csvLabels">
                      <button class="btn btn-dark-blue btn-sm mr-3"><Feather class="icon-xs" type="download" /> Export</button>
                    </vue-json-to-csv>
                    <label class="d-inline-flex align-items-center">
                      Search:
                      <b-form-input
                          v-model="datatable.queries.search"
                          type="search"
                          placeholder="Search..."
                          class="form-control form-control-sm ml-2"
                      ></b-form-input>
                    </label>
                    <button
                        class="ml-1 btn btn-dark-blue btn-sm"
                        @click="getBusinesses"
                    >
                      <i class="uil uil-search-alt"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="table-responsive mb-0 customer-table">
                <div v-if="leads.length" class="tablerounededCorner">
                  <b-table
                      :items="leads"
                      :fields="datatable.columns"
                      :sort-by.sync="datatable.queries.sort_by"
                      :sort-desc.sync="datatable.queries.sort_desc"
                      :per-page="datatable.queries.per_page"
                      data-export-types="['csv']"
                      :no-local-sorting="true"
                      :striped="true"
                      thead-class="border-0"
                      class="roundedTable"
                      @sort-changed="sortChanged"
                  >
                    <div class="text-center text-danger my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                    <template v-slot:cell(status)="data">
                      <span class="font-weight-bold" :style="'color:' + statusColor(data.item.status)">{{data.item.status}}</span>
                    </template>
                    <template v-slot:cell(connected)="data">
                      <span v-if="data.item.location" class="text-success font-size-20"><i class="uil uil-check" /></span>
                      <span v-else class="text-danger font-size-20"><i class="uil uil-times" /></span>
                    </template>
                    <template v-slot:cell(actions)="data">
                      <a :href="data.item.google_maps_url" class="mr-1" target="_blank"><img style="max-height: 30px" src="https://review-link.sfo3.cdn.digitaloceanspaces.com/Icon.png"></a>
                      <router-link
                          :to="{
                        name: 'agency.campaign-lead',
                        // eslint-disable-next-line vue/camelcase
                        params: { id: campaign_id, lead: data.item.id },
                      }"
                          class="btn btn-success btn-sm ml-1"
                      >
                        View
                      </router-link>
                    </template>
                  </b-table>
                </div>
                <div v-else class="text-center">No Leads</div>
              </div>
              <div class="row mt-4">
                <div class="col">
                  <div class="float-left mt-2">
                    Total {{ totalLeads }} Contacts
                  </div>
                  <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                  >
                    <ul class="pagination pagination-rounded mb-0">
                      <b-pagination
                          v-model="datatable.queries.page"
                          :total-rows="totalLeads"
                          :per-page="datatable.queries.per_page"
                          @change="onPageChanged"
                      ></b-pagination>
                    </ul>
                  </div>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueJsonToCsv from 'vue-json-to-csv'
export default {
  components:{
    VueJsonToCsv
  },
  data() {
    return {
      json_export_labels:{

      },
      datatable: {
        items: [],
        totalItems: 1,
        columns: [
          { key: 'small_date', label: 'Date', sortable: false },
          { key: 'business_name', label: 'Business', sortable: false },
          { key: 'full_name', label: 'Name', sortable: false },
          { key: 'email', label: 'Email', sortable: false },
          { key: 'phone_number', label: 'Phone', sortable: false },
          { key: 'status', label: 'Status', sortable: false },
          { key: 'actions', label: 'G Link', class:'actions lm-dashboard', width: 100  },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'All',
          search: null,
          sort_by: 'id',
          sort_desc: true,
        },
      },
      csvLabels:{
        first_name: { title: 'First name' },
        last_name: { title: 'Last name' },
        email: { title: 'Email' },
        phone_number: { title: 'Phone number' },
        business_name: { title: 'Business name' },
        business_phone: { title: 'Business phone' },
        business_website: { title: 'Business website' },
        address: { title: 'Address' },
        city: { title: 'City' },
        state: { title: 'State' },
        zip: { title: 'ZIP' },
        country: { title: 'Country' },
        place_id: { title: 'Place ID' },
        types: { title: 'Types' },
        google_maps_url: { title: 'Google Maps URL' },
        lat: { title: 'Latitude' },
        lng: { title: 'Longitude' },
        status: { title: 'Status' },
        unique_id: { title: 'Unique ID' },
        rating: { title: 'Rating' },
        review_count: { title: 'Review count' },
        scanned: { title: 'Scanned' },
        mailed: { title: 'Mailed' },
        is_lead: { title: 'Is lead' },
        created_at: { title: 'Created at' },
        updated_at: { title: 'Updated at' },
        full_name: { title: 'Full name' },
        primary_category: { title: 'Primary category' },
        pretty_phone: { title: 'Pretty phone' },
      },
      selectedBusiness: null,
      loadingBusinesses: false,
      loadingImpersonate: false,
      loadingRemove: null,
      createIsBusy:false,
      showCreateLeadModal:false,
      campaign_id:null,
      analyticsQuery:'Last 30 Days',
      analytics:null,
      loadingAnalytics:false,
      leads:[],
      totalLeads:0
    }
  },

  computed: {

    reload() {
      return this.$store.getters['leads/reload']
    },

    user() {
      return this.$store.getters['auth/user']
    },

    routeName(){
      return this.$route.name;
    },
  },

  watch: {
    reload: (val) => {
      console.log(val)
    },
  },

  created() {
    this.campaign_id = this.$route.params.id;
    this.$store.dispatch('leads/setCurrentPage', 'index')
  },

  mounted() {
    this.getBusinesses();
  },

  methods: {
    csvTitle(){
      return 'lead-machine-leads-export-'+Date.now()
    },
    sortChanged(args) {
      this.datatable.queries.sort_by = args.sortBy
      this.datatable.queries.sort_desc = args.sortDesc
      this.datatable.queries.page = 1
      this.getBusinesses()
    },

    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getBusinesses()
    },

    getBusinesses() {
      this.loadingBusinesses = true
      this.$store
          .dispatch('leads/getAllLeads', {
            agencyId: this.user.agency.id,
            params: this.datatable.queries,
          })
          .then((res) => {
            this.leads = res.data.data;
            this.loadingBusinesses = false;
            this.totalLeads = res.data.total
          })
          .catch(() => {
            this.loadingBusinesses = false
          })
    },
  },
}
</script>
<style lang="scss" scoped>
.status-filter{
  text-transform: capitalize;
}
.big-numbers{
  font-size: 3.5em;
  font-weight: bold;
  line-height: 1.25em;
  &.smaller{
    margin-top: 7px;
    font-size: 2.5em;
    color:#548235;
  }
  small{
    color: #737373;
    font-size: 14px;
  }
  i.uil{
    font-size: .75em;
    margin-left: -15px;
  }
}
.stat-head{
  margin-top: 10px;
  font-weight: bold;
  color: #737373;
  font-size: 1.4em;
}
.stat-foot{
  color: #17244d;
  font-size: 1.3em;
  margin-bottom: 10px;
}
.text-darker-blue{
  color: #17244d;
}

</style>
<style>
.actions.lm-dashboard{
  width: 100px;
}
.pac-container.pac-logo {
  z-index: 10000000;
}
.customer-table td.actions {min-width:200px; max-width: 200px;}
</style>
